// import { type JSSThemeDefaultFooterType } from '../JSSThemeDefaultFooter';
// @flow
import { type JSSThemeDefaultFooterAllType } from '../JSSThemeDefaultFooterAll';

export const JSSThemeFooterAll60042: JSSThemeDefaultFooterAllType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { white, primary },
    },
  } = themeVars;
  return {
    legal: {
      paddingTop: '60px',
    },
    footerWrap: {
      background: primary,
      color: white,
    },
    licenseLink: {
      paddingRight: 0,
      '&:last-child': {
        display: 'none',
      },
    },
    menuWrapper: {
      width: '100%',
      '@media screen and (max-width: 1009px)': {
        paddingBottom: '0',
      },
    },
    newStyle: {
      display: 'grid',
      gridTemplateColumns: 'repeat(7, 1fr)',
      borderBottom: '1px solid #6889c0',
      paddingBottom: 20,
      width: '100%',
      rowGap: '10px',
      '@media screen and (max-width: 768px)': {
        gridTemplateColumns: 'repeat(4, 1fr)',
        rowGap: '5px',
      },
      '@media screen and (max-width: 440px)': {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
    },
    logosContainer: {
      display: 'block',
      margin: 10,
      textAlign: 'center',
      '@media screen and (max-width: 440px)': {
        margin: 6,
      },
    },
    logoSvg: {
      fill: white,
      width: 89,
      maxHeight: 30,
      height: '100%',
      '@media screen and (max-width: 440px)': {
        maxHeight: 20,
      },
    },
    logoSection: {
      width: '100%',
    }
  };
};
